import React from "react";
import PropTypes from "prop-types";
import Typed from "react-typed";
import Social, { SocialLink } from "@ui/social";
import SkillShare, { SkillItem } from "@ui/skill-share";
import Image from "@ui/image";
import Icon from "@ui/icon";
import {
    ImageType,
    HeadingType,
    TextType,
    SocialType,
    SkillType,
} from "@utils/types";

const HeroArea = ({ data, id }) => {
    return (
        <div id={id} className="rn-slider-area" style={{ marginTop: -80 }}>
            <div className="slide slider-style-1">
                <div className="container">
                    <div className="row row--30 align-items-center">
                        <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                            <div className="content">
                                <div className="inner">
                                    {data?.headings?.[0] && (
                                        <span className="subtitle" style={{ backgroundColor: 'yellow', color: 'black' }}>
                                            {data.headings[0].content}
                                        </span>
                                    )}

                                    {data?.headings?.[1] && (
                                        <h1 className="title">
                                            <span
                                                className="title-main"
                                                dangerouslySetInnerHTML={{
                                                    __html: data.headings[1]
                                                        .content,
                                                }}
                                            />
                                            <br />
                                            {data?.animated_texts && (
                                                <span
                                                    className="header-caption"
                                                    id="page-top"
                                                >
                                                    <span className="cd-headline clip is-full-width">
                                                        <span>experienced on </span>{" "}
                                                        <Typed
                                                            className="animated-texts"
                                                            strings={
                                                                data.animated_texts
                                                            }
                                                            typeSpeed={50}
                                                            backSpeed={50}
                                                            backDelay={1}
                                                            loop
                                                            smartBackspace
                                                        />
                                                    </span>
                                                </span>
                                            )}
                                        </h1>
                                    )}
                                    {data?.texts?.[0] && (
                                        <div>
                                            <p className="description">
                                                {data.texts[0].content}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                                        {data?.socials && (
                                            <div className="social-share-inner-left">
                                                <span className="title">
                                                    meet with me
                                                </span>
                                                <Social>
                                                    {data.socials.map(
                                                        (social) => (
                                                            <SocialLink
                                                                key={social.id}
                                                                path={
                                                                    social.path
                                                                }
                                                            >
                                                                <Icon
                                                                    name={
                                                                        social.icon
                                                                    }
                                                                />
                                                            </SocialLink>
                                                        )
                                                    )}
                                                    <SocialLink
                                                        key={6}
                                                        path={
                                                            "https://www.credly.com/badges/c255fbc4-5366-42a8-bf1c-61384c83a572"
                                                        }
                                                    >
                                                        <Image
                                                            src={"https://d3gih7jbfe3jlq.cloudfront.net/AWS-Podcast-Title-Art.jpg"}
                                                            alt={"AWS"}
                                                        />
                                                    </SocialLink>
                                                </Social>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mt_mobile--30">
                                        {data?.skills && (
                                            <div className="skill-share-inner">
                                                <span className="title">
                                                    here is my cv and e-mail
                                                </span>
                                                <SkillShare>
                                                    {data.skills.map(
                                                        (skill, index) => (
                                                            <SocialLink
                                                                key={skill.id}
                                                                path={index === 0 ? 'https://drive.google.com/drive/folders/1NwQ5KhVv4c2qx_FTVRfr679DRDHW9tFV' : 'mailto:info@emrecan.co'}
                                                            >
                                                                <Icon
                                                                    name={index === 0 ? 'File' : 'Mail'}
                                                                />
                                                            </SocialLink>
                                                            // <SkillItem
                                                            //     key={skill.id}
                                                            //     image={
                                                            //         skill.image
                                                            //     }
                                                            // />
                                                        )
                                                    )}
                                                </SkillShare>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="order-1 order-lg-2 col-lg-5">
                            {data?.images?.[0]?.src && (
                                <div className="thumbnail">
                                    <div className="inner">
                                        <Image
                                            src={data.images[0].src}
                                            alt={data.images[0]?.alt || "Hero"}
                                        />
                                        <a href="https://www.credly.com/badges/c255fbc4-5366-42a8-bf1c-61384c83a572" target="_blank">
                                            <div className="aws-image-container" style={{ position: "absolute", top: 0, right: "75%" }}>
                                                <Image
                                                    src={"https://images.credly.com/size/340x340/images/b9feab85-1a43-4f6c-99a5-631b88d5461b/image.png"}
                                                    alt={"AWS"}
                                                />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="row flex h-25vh w-full align-items-center" style={{ marginTop: 80, border: 'dashed 2px white', padding: 10 }}>
                        <div className="col-lg-3 h-full">
                            <Image
                                src={"https://miro.medium.com/v2/resize:fit:720/format:webp/1*2R_Y0L5I92q8FonEG1VoWw.png"}
                                alt={"LibraryAppBlogImage"}
                                className={"w-full object-cover"}
                            />
                        </div>
                        <a href="https://paradyo.medium.com/how-an-engineer-builds-software-library-app-786a1bc95cf8" target="_blank" className="col-lg-9 h-full flex items-center pl-4 pr-8 hover:bg-gray-100">
                            <h4 className="text-lg font-semibold">How an engineer builds software? Library App</h4>
                            <span>
                                How do you think an engineer builds software? I am showing all the steps to build a Library app!
                            </span>
                        </a>
                    </div>
                    <div class="row flex h-25vh w-full align-items-center" style={{ border: 'dashed 2px white', padding: 10 }}>
                        <div className="col-lg-3 h-full">
                            <Image
                                src={"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*q5woevxErBX1YRK6AvTVWA.png"}
                                alt={"RestAPIDesignBlogImage"}
                                className={"w-full object-cover"}
                            />
                        </div>
                        <a href="https://paradyo.medium.com/how-to-design-a-rest-api-correctly-86f91585abc1" target="_blank" className="col-lg-9 h-full flex items-center pl-4 pr-8 hover:bg-gray-100">
                            <h4 className="text-lg font-semibold">How to design a REST API, correctly?</h4>
                            <span>
                                How should you design a REST API? I am showing all the important points to desing a correct RESTful API!
                            </span>
                        </a>
                    </div>
                    <div class="row flex h-25vh w-full align-items-center" style={{ border: 'dashed 2px white', padding: 10 }}>
                        <div className="col-lg-3 h-full">
                            <Image
                                src={"https://miro.medium.com/v2/resize:fit:720/format:webp/1*9OrfpbNv2KwzE1aU4X8JMg.png"}
                                alt={"OOPDesignBlogImage"}
                                className={"w-full object-cover"}
                            />
                        </div>
                        <a href="https://paradyo.medium.com/how-to-design-an-object-oriented-architecture-perfectly-85d8f4a9c151" target="_blank" className="col-lg-9 h-full flex items-center pl-4 pr-8 hover:bg-gray-100">
                            <h4 className="text-lg font-semibold">How to design an object-oriented architecture, perfectly?</h4>
                            <span>
                                How should you design an object-oriented architecture? Based on SOLID principles, I created this content.
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

HeroArea.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        animated_texts: PropTypes.arrayOf(PropTypes.string),
        socials: PropTypes.arrayOf(PropTypes.shape(SocialType)),
        skills: PropTypes.arrayOf(PropTypes.shape(SkillType)),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    }),
};

HeroArea.defaultProps = {
    id: "home",
};

export default HeroArea;
